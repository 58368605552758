@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import 'assets/styles/global.scss'; 

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: Montserrat, sans-serif;
  height: 100%;
}



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.cdk-overlay-dark-backdrop {
  background: rgb(0 0 0 / 82%);
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(50px);
}

.mat-mdc-form-field .mdc-text-field {
  border-radius: 8px;
  border: 1px solid var(--greayscale-grey-2, #e8e8e8);
  background: var(--greayscale-grey-3, #f3f4f3);
}

.editor-content > * {
  max-width: min(90vw, 1280px) !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.editor-content > .alignfull {
  max-width: none !important;
}

.mat-mdc-tab {
  &-header {
    border-bottom: 1px solid #e8e8e8;
  }
  &.mdc-tab--active {
    .mdc-tab__text-label {
      color: #5c9659 !important;
    }
  }
  .mdc-tab-indicator__content--underline {
    border-color: #5c9659 !important;
  }
}

.cdk-overlay-connected-position-bounding-box .mat-mdc-menu-panel {
  .mat-mdc-menu-item {
    &:not([disabled]):hover {
      background-color: #f2f7f2 !important;
    }
  }
}

.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: #5c9659 !important;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: #5c9659 !important;
  border-color: #5c9659 !important;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}

.mat-icon {
  @media (max-width: 768px) {
    font-size: 16px;
    height: 16px !important;
    width: 16px !important;
  }
}
