.pac-container {
  border-top: none;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  
  .pac-item {
    padding: 5px 16px;
    font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
    border: none;
  }
}

.pac-logo:after {
  padding: 12px;
}
