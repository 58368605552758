@import './variables.scss';
@import './scrollbars.scss';
@import './google-pac.scss';
@import './ng-select.scss';

.error {
  color: var(--error);
}

.mat-facebook {
  background-color: #3975EA !important;
  color: white !important;
}
