.ng-select {
  .ng-select-container {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    color: #747674;
    border-radius: 8px !important;
    border-color: #e8e8e8 !important;
    background: #fefefe;
    max-height: 38px;

    .ng-value-container {
      .ng-value {
        max-width: unset !important;
        border-color: #f2f7f2 !important;
        background: #f2f7f2 !important;
        color: #5c9659 !important;
        border-radius: 4px !important;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        padding: 2px 5px;
      }
    }
  }
}
